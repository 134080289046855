<template>
    <div>
        <div style="margin: 1rem; display: flex; gap: 1rem">
            <!--<button class="download" @click.prevent="download">Download</button>-->
            <button class="print" @click.prevent="print()">Preview</button>
        </div>

        <div style="display: flex; justify-content: space-around">
            <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :paginate-elements-by-height="1125"
                :paginate-elements-by-width="1125"
                :enable-download="true"
                :preview-modal="true"
                :filename="site.name_and_surname"
                :htmlToPdfOptions="{
                    margin: [0, 0],
                    filename: site.name_and_surname,
                    jsPDF: {
                        format: 'a4',
                        html2canvas:  { scale: 2 },
                        orientation: 'landscape'
                    }
                }"
                :pdf-quality="2"
                :manual-pagination="false"
                :margin="0"
                pdf-content-width="1125px"
                pdf-content-height="1125px"
                pdf-format="a4"
                pdf-orientation="landscape"
                ref="html2Pdf"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)"
            >
                <div class="body" slot="pdf-content">
                    <div class="body__container">
                        <div class="container_1">
                            <div class="image">
                                <img src="../../../../assets/images/Image2.png"/>
                                <img src="../../../../assets/images/Image1.png"/>
                                <img src="../../../../assets/images/Image3.png"/>
                            </div>
                            <div class="container">
                                <h1><b>CERTIFICATE OF ACHIEVEMENT</b></h1>
                                <h3>This certificate is awarded to</h3>
                                <h1><b>{{site.name_and_surname}}</b></h1>
                                <h3>IN RECOGNITION OF OUTSTANDING PERFORMANCE AT THE EARLY INFANT DIAGNOSIS POINT OF CARE (EID POC) CERTIFICATION EXAMINATION SEPTEMBER 2021 SESSION</h3>
                                <h3><b>On October, 2021</b></h3>
                                <div class="container__footer">
                                    <div>
                                        <hr>
                                        <span>Executive Director<br>
                                            Global Health Systems Solutions
                                        </span>
                                    </div>
                                    <div>
                                        <hr>
                                        <span>Administrator<br>
                                            National Public Health Laboratory
                                        </span>
                                    </div>
                                    <div>
                                        <hr>
                                        <span>Directeur, Direction de la Pharmacie,<br>
                                            du Médicament et des Laboratoires (DPML)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vue-html2pdf>
        </div>
    </div>
</template>

<script>
import { axios_get } from '../../../../helpers/helper';
import VueHtml2pdf from 'vue-html2pdf'
export default {
    components: {
        VueHtml2pdf
    },
    data(){
        return{
            site:null,
            ptround:null,
        }
    },

    mounted(){
        axios_get("/certif/"+this.$route.params.id, {}, (d)=>{
            this.site = d[0]
            console.log(this.site)
        }, (e)=>{
            console.log('error', e)
        }, ()=>{
            console.log('finish')
        })
    },

    methods:{
        print(){
            this.$refs['html2Pdf'].generatePreviewPdf()
        },
        download(){
            this.$refs['html2Pdf'].generatePdf()
        },

        async beforeDownload ({ html2pdf, options=this.setOptions, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                console.log(pdf)
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    //pdf.setPage(i)
                    //pdf.setFontSize(10)
                    //pdf.setTextColor(150)
                    //pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
    }
}
</script>

<style lang="scss">
    @import '../../../../assets/scss/certification-1.scss';
</style>
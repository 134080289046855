var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin":"1rem","display":"flex","gap":"1rem"}},[_c('button',{staticClass:"print",on:{"click":function($event){$event.preventDefault();return _vm.print()}}},[_vm._v("Preview")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"paginate-elements-by-height":1125,"paginate-elements-by-width":1125,"enable-download":true,"preview-modal":true,"filename":_vm.site.name_and_surname,"htmlToPdfOptions":{
                margin: [0, 0],
                filename: _vm.site.name_and_surname,
                jsPDF: {
                    format: 'a4',
                    html2canvas:  { scale: 2 },
                    orientation: 'landscape'
                }
            },"pdf-quality":2,"manual-pagination":false,"margin":0,"pdf-content-width":"1125px","pdf-content-height":"1125px","pdf-format":"a4","pdf-orientation":"landscape"},on:{"progress":function($event){return _vm.onProgress($event)},"startPagination":function($event){return _vm.startPagination()},"hasPaginated":function($event){return _vm.hasPaginated()},"beforeDownload":function($event){return _vm.beforeDownload($event)},"hasDownloaded":function($event){return _vm.hasDownloaded($event)}}},[_c('div',{staticClass:"body",attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"body__container"},[_c('div',{staticClass:"container_1"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../../../../assets/images/Image2.png")}}),_c('img',{attrs:{"src":require("../../../../assets/images/Image1.png")}}),_c('img',{attrs:{"src":require("../../../../assets/images/Image3.png")}})]),_c('div',{staticClass:"container"},[_c('h1',[_c('b',[_vm._v("CERTIFICATE OF ACHIEVEMENT")])]),_c('h3',[_vm._v("This certificate is awarded to")]),_c('h1',[_c('b',[_vm._v(_vm._s(_vm.site.name_and_surname))])]),_c('h3',[_vm._v("IN RECOGNITION OF OUTSTANDING PERFORMANCE AT THE EARLY INFANT DIAGNOSIS POINT OF CARE (EID POC) CERTIFICATION EXAMINATION SEPTEMBER 2021 SESSION")]),_c('h3',[_c('b',[_vm._v("On October, 2021")])]),_c('div',{staticClass:"container__footer"},[_c('div',[_c('hr'),_c('span',[_vm._v("Executive Director"),_c('br'),_vm._v(" Global Health Systems Solutions ")])]),_c('div',[_c('hr'),_c('span',[_vm._v("Administrator"),_c('br'),_vm._v(" National Public Health Laboratory ")])]),_c('div',[_c('hr'),_c('span',[_vm._v("Directeur, Direction de la Pharmacie,"),_c('br'),_vm._v(" du Médicament et des Laboratoires (DPML) ")])])])])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }